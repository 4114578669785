import React, { useState, useEffect } from 'react';
import ROSLIB from 'roslib';
import './app.css';
import './c3.css';
import RTChart from 'react-rt-chart';

const Section = (props) => {
  const { title, ros, refreshFun } = props;

  const [showData, setShowData] = useState(false);
  let [data, setData] = useState([]);
  const [autoRefresh] = useState(false);
  const [filter, setFilter] = useState('');

  const refresh = () => {
    ros[refreshFun]((data) => {
      setData(data);
    })
  }

  useEffect(refresh, []);

  if (autoRefresh) {
    setInterval(() => {
      refresh();
    }, 5000)
  }

  if (Array.isArray(data)) {
    data = (data.filter(i => i.includes(filter)));
  }

  return (
    <div>
      <div id="info-header">
        <h2>{title} ({data.length})</h2>
        <button onClick={() => setShowData(!showData)}>
          {
            showData ? 'Hide' : 'Show'
          }
        </button>
        <button onClick={refresh}>
          Refresh
          </button>
        <input type="text"
          name={title}
          onChange={(event) => setFilter(event.target.value)} />
      </div>

      {
        showData && (<div>
          {Array.isArray(data) ?
            (
              <div id="info-list">
                {
                  data.map((i, key) => <p key={key}>{i}</p>)
                }
              </div>
            ) : (
              Object.keys(data).map((i, key) => {
                return (
                  <div key={key}>
                    <h3>{i}</h3>

                    <div id="info-list">
                      {
                        data[i].map((i, key) => <p key={key}>{i}</p>)
                      }
                    </div>
                  </div>
                )
              })
            )}
        </div>)
      }
    </div>
  );
}

export default () => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState('ws://localhost:9090');
  const [newUrl, setNewUrl] = useState('ws://localhost:9090');
  const [data, setData] = useState({});
  const [image, setImage] = useState('');
  const [depthImage, setDepthImage] = useState('');
  const [ros, setRos] = useState(null);
  const [showGraph, setShowGraph] = useState(false);

  const setupROSSubscribers = () => {
    let ros = new ROSLIB.Ros({
      url
    });

    setRos(ros);

    ros.on('error', (i) => {
      console.log('error')
      setError(true);
    });

    ros.on('connection', (i) => {
      console.log('connection')
      setConnected(true);
      setError(false);
    });

    ros.on('close', (i) => {
      console.log('close')
      setConnected(false);
    });

    let sensors = new ROSLIB.Topic({
      ros,
      name: '/sensors',
      messageType: 'common/Sensors'
    });

    let camera = new ROSLIB.Topic({
      ros,
      name: '/camera/rgb/image_rect_color/compressed',
      messageType: 'sensor_msgs/CompressedImage'
    });

    let depth = new ROSLIB.Topic({
      ros,
      name: '/camera/depth/image_raw/compressed',
      messageType: 'sensor_msgs/CompressedImage'
    });

    sensors.subscribe((data) => {
      delete data.header;
      setData(data);
    });

    camera.subscribe((message) => {
      setImage('data:image/jpg;base64,' + message.data);
    });

    depth.subscribe((message) => {
      setDepthImage('data:image/jpg;base64,' + message.data);
    });
  }

  useEffect(setupROSSubscribers, []);

  let newData = Object.assign({}, data);
  newData.date = new Date();

  let isConnected = ros ? ros.isConnected : false;

  console.log({
    error,
    isConnected
  });

  return (
    <div className="App">
      <div>
        <h1>Dashboard</h1>
        {
          (ros && (error || !isConnected)) &&
          <div>
            <p>Cannot establish a connection at <b>{url}</b>. Is ROS and the webserver running?</p>
            <input type="text"
              defaultValue={url}
              onChange={(e) => setNewUrl(e.target.value)} />
            <button onClick={() => {
                setUrl(newUrl || url);
                ros.connect(url);
              }}>Retry</button>
          </div>
        }
        {
          (ros && connected) &&
            <div>
              <div>
                {
                  Object.keys(data).map((i, key) => (
                    <div key={key}
                      className="sensor">
                      <p className="sensor-title">{i}</p>
                      <p>{JSON.stringify(data[i])}</p>
                    </div>
                  ))
                }
                <div>
                  <input type='checkbox' 
                    defaultValue={showGraph} 
                    onChange={() => setShowGraph(!showGraph)}/>
                  <p>View graph</p>
                </div>
                {
                  showGraph && 
                  <RTChart data={newData}
                    reset={!showGraph}
                    fields={['temperature', 'yaw', 'longitude', 'humidity', 'latitude']} />
                }
              </div>

              <div id="kinect">
                <div>
                  <h2>RGB Image</h2>
                  <img src={image} 
                    alt='Kinect RGB'/>
                </div>
                <div>
                  <h2>Depth Image</h2>
                  <img src={depthImage} 
                    alt='Kinect IR depth'/>
                </div>
              </div>

              <Section title="Topics"
                refreshFun="getTopics"
                ros={ros} />

              <Section title="Services"
                refreshFun="getServices"
                ros={ros} />

              <Section title="Nodes"
                refreshFun="getNodes"
                ros={ros} />

              <Section title="Params"
                refreshFun="getParams"
                ros={ros} />
            </div>
        }
      </div>
    </div>
  );
}